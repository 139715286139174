export const sendEmptyCartIsOpened = () => {
  window.dataLayer.push({
    'event': 'empty_cart_open',
  });
};

export const sendOpenRecommendationsClick = (place: 'cart' | 'header' | 'bottom_block_on_main_page' = 'cart') => {
  window.dataLayer.push({
    'event': 'cart_for_me_button_click',
    place,
  });
};
