import { GetProductIsFavoriteFunc } from 'src/services/favorites/useFavoritesService';
import { ViewProduct } from '../../components/views/ProductsCarousel/ProductsCarousel';
import { Product } from '../../redux/apiTypes';
import { CartItem } from '../../redux/cart/general/cartTypes';

function mapViewProducts(
  products: Product[], getProductIsFavorite: GetProductIsFavoriteFunc, cartItems: CartItem[],
): ViewProduct[] {
  return products?.map(
    product => {
      const cartItem = cartItems.find(i => i.ean === product.ean);

      return {
        ...product,
        isFavorite: getProductIsFavorite(product.ean),
        isAdded: !!cartItem,
        amount: cartItem?.amount,
      };
    },
  );
}

export default mapViewProducts;
