import { weightMeasureEnum } from "src/fixtures";
import { ProductUnit } from "src/redux/apiTypes";
import { LocalizeFunc } from "src/services/localization/types";
import getBundleInfo from "./getBundleInfo";
import getFormattedWeight from "./getFormattedWeight";

export interface Params {
  unit: ProductUnit;
  weight: number;
  volume: number | null;
  bundle: number | null;
  localize: LocalizeFunc;
}

export const getWeightLabel = ({ unit, weight, volume, bundle, localize }: Params) => {
  let weightLabel = localize('general_one_kg');

  if(unit === weightMeasureEnum.pcs) {
    weightLabel = getFormattedWeight(unit, weight, volume, localize) || '';
  }

  const bundleInfo = getBundleInfo(bundle, localize);

  return `${weightLabel}${bundleInfo}`;
};
