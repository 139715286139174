import css from 'styled-jsx/css';
import { desktop } from '../../../../styles/media';

export const styles = css`
  .FavoriteButton {
    box-sizing: content-box;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0px;
    margin: 0;
    color: var(--colors-brandLightGrey2);
    transition: 0.2s background-color ease-in-out;
  }

  .FavoriteButton:hover {
    background: var(--colors-brandLightGrey5);
  }

  .FavoriteButton_small {
    width: 24px;
    height: 24px;
  }

  .FavoriteButton_big {
    width: 36px;
    height: 36px;
  }

  .FavoriteButton__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .FavoriteButton__icon {
    font-size: 15px;
    line-height: 16px;
    color: var(--colors-brandLightGrey8)
  }

  .FavoriteButton__icon-full {
    color: var(--colors-red);
    display: none;
  }

  .FavoriteButton__icon-wrapper_checked .FavoriteButton__icon {
    display: none;
  }

  .FavoriteButton__icon-wrapper_checked .FavoriteButton__icon-full {
    display: inline;
  }

  @media ${desktop} {
    .FavoriteButton:hover .FavoriteButton__icon {
      color: var(--colors-red);
    }
  }
  `;
