import cn from 'classnames';
import { MouseEventHandler } from 'react';
import { styles } from './FavoriteButton.styles';

interface FavoriteButtonProps {
  tooltipText: string;
  isChecked?: boolean;
  onFavoriteClick(): void;
  size?: 'small' | 'big';
}

const FavoriteButton = (props: FavoriteButtonProps) => {
  const { onFavoriteClick, isChecked, tooltipText, size = 'small' } = props;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onFavoriteClick();
  };

  return (
    <button
      className='FavoriteButton'
      onClick={handleClick}
      data-testid='favorites-default'
      data-tip={tooltipText}
      data-for='favorite'
      data-marker='Add product to list'
    >
      <div className={
        cn(
          'FavoriteButton__icon-wrapper', `FavoriteButton_${size}`, {
          'FavoriteButton__icon-wrapper_checked': isChecked,
        })
      }>
        <span className='FavoriteButton__icon icon-heart'/>
        <span className='FavoriteButton__icon-full icon-heart-full'/>
      </div>

      <style jsx>{styles}</style>
    </button>
  );
};

export default FavoriteButton;
