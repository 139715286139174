import { ProductBadgeParams } from "src/hooks/product/useProductBadges";
import { Product } from "src/redux/apiTypes";

export const getBadgeParamsFromProduct = (product: Product): ProductBadgeParams => {
  return {
    is_hit: product.is_hit,
    is_new_product: product.is_new_product,
    bundle: product.bundle,
    discount: product.discount,
    own_brand_data: product.own_brand_data,
    price_wholesale: product.price_wholesale,
  };
};
