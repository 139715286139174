import { Discount } from "src/redux/apiTypes";
import Price from "../../Price/Price";

interface Props {
  price: number;
  discount: Discount;
  currency: string;
}

const ProductRowPrice = (props: Props) => {
  const { price, discount, currency } = props;
  const discountAvailable = discount.status;

  return (
    <div className='ProductRowPrice'>
      {
        discountAvailable &&
        <div
          className="ProductRowPrice__price ProductRowPrice__price_withMargin"
          data-testid='oldPrice'
        >
          <Price
            price={discount.old_price}
            currency={currency}
            variant="smallCaption"
            isMinor
            dataMarker="Old Price"
          />
        </div>
      }

      <div className="ProductRowPrice__price">
        <Price
          price={price}
          currency={currency}
          isDiscount={discountAvailable}
          dataMarker="Price"
        />
      </div>

      <style jsx>{`
        .ProductRowPrice__price {
          font-size: 14px;
        }
        .ProductRowPrice__price_withMargin {
          margin-bottom: -4px;
        }
      `}</style>
    </div>
  );
};


export default ProductRowPrice;
